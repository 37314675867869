import React from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';

const CustomDivider = ({ title }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Divider sx={{ flex: 1 }} />
      <Typography
        variant="title"
        sx={{
          color: theme.palette.text.disabled,
          mx: 2,
        }}
      >
        {title}
      </Typography>
      <Divider sx={{ flex: 1 }} />
    </Box>
  );
};

export default CustomDivider;
