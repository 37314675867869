import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import TopBar from '../../../components/TopBar';
import { useSelector } from 'react-redux';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import CustomDivider from '../../../components/CustomDivider';
import CustomStringInput from '../../../components/CustomStringInput';
import CustomSelectInput from '../../../components/CustomSelectInput';

export default function SupplierForm({ initialData, onSubmit, onCancel }) {
  useDocumentTitle('New Supplier Form');
  const [formData, setFormData] = useState(initialData);

  const [errors, setErrors] = useState({});
  const states = useSelector((state) => state.states.states);
  const msicCodes = useSelector((state) => state.msicCodes.msicCodes);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);

  const { error } = useSelector((state) => state.suppliers);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  useEffect(() => {
    if (error) {
      setErrors(error);
    }
  }, [error]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[name]) {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  return (
    <Box sx={{ p: 4 }}>
      <TopBar title={'Supplier Form'}>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancel}
            className="action-button"
            sx={{ marginLeft: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            label="Save"
            onClick={() => onSubmit(formData)}
            className="action-button"
            sx={{ marginLeft: 1 }}
          >
            Save
          </Button>
        </Box>
      </TopBar>

      <Grid container spacing={2}>
        <CustomStringInput
          key="name"
          field={{ key: 'name', label: 'Name', required: true }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="company_name"
          field={{ key: 'company_name', label: 'Company Name', required: false }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="company_name_in_local"
          field={{ key: 'company_name_in_local', label: 'Company Name in Local Language', required: false }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="email"
          field={{ key: 'email', label: 'Email', required: true }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />

        <Grid item xs={12}>
          <CustomDivider title="Address" />
        </Grid>

        <CustomStringInput
          key="address1"
          field={{ key: 'address1', label: 'Address 1', required: true }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="address2"
          field={{ key: 'address2', label: 'Address 2', required: false }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="address3"
          field={{ key: 'address3', label: 'Address 3', required: false }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="city"
          field={{ key: 'city', label: 'City', required: true }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomSelectInput
          key="country_code"
          field={{ key: 'country_code', label: 'Country', required: true }}
          options={countryCodes}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
          labelKey={'Country'}
          valueKey={'Code'}
        />
        <CustomSelectInput
          key="state"
          field={{ key: 'state', label: 'State', required: true }}
          options={states}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
          labelKey={'State'}
          valueKey={'Code'}
        />
        <CustomStringInput
          key="postal_code"
          field={{ key: 'postal_code', label: 'Postal Code', required: false }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="phone"
          field={{ key: 'phone', label: 'Phone', required: true }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />

        <Grid item xs={12}>
          <CustomDivider title="Business Identifiers" />
        </Grid>

        <CustomSelectInput
          key="registration_type"
          field={{ key: 'registration_type', label: 'Registration Type', required: true }}
          options={[
            {
              label: 'BRN',
              value: 'BRN',
            },
            {
              label: 'PASSPORT',
              value: 'PASSPORT',
            },
            {
              label: 'ARMY',
              value: 'ARMY',
            },
            {
              label: 'NRIC',
              value: 'NRIC',
            },
          ]}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="registration_number"
          field={{ key: 'registration_number', label: 'Registration Number', required: true }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="tin"
          field={{ key: 'tin', label: 'TIN', required: true }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="sst_registration_number"
          field={{
            key: 'sst_registration_number',
            label: 'SST Registration Number',
            required: false,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          key="tourism_tax_registration_number"
          field={{
            key: 'tourism_tax_registration_number',
            label: 'Tourism Tax Registration Number',
            required: false,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomSelectInput
          key="msic_code"
          field={{ key: 'msic_code', label: 'MSIC Code', required: true }}
          options={msicCodes}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
          labelKey={'Description'}
          valueKey={'Code'}
        />
      </Grid>
    </Box>
  );
}
