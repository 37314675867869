import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GoToEditActionMenuItem = ({ supplier }) => {
  const navigate = useNavigate();

  const onClickingDetails = () => {
    navigate('/suppliers/:id/edit'.replace(':id', supplier.profile_id));
  };
  return (
    <>
      <MenuItem key="edit" onClick={onClickingDetails}>
        Edit
      </MenuItem>
    </>
  );
};
