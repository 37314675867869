import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countryCodes: [],
  loading: false,
  error: null,
};

const countryCodesSlice = createSlice({
  name: 'countryCodes',
  initialState,
  reducers: {
    fetchCountryCodesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCountryCodesSuccess(state, action) {
      state.loading = false;
      state.countryCodes = action.payload;
    },
    fetchCountryCodesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchCountryCodesRequest, fetchCountryCodesSuccess, fetchCountryCodesFailure } =
  countryCodesSlice.actions;

export default countryCodesSlice.reducer;
