import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoicesRequest, markInvoiceCreated } from '../../api/invoices/invoicesSlice';
import CustomTable from '../../components/CustomTable';
import InvoiceFilters from './InvoiceFilters';
import InvoiceActions from './InvoiceActions';
import TopBar from '../../components/TopBar';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import LeadingActions from './LeadingActions';

function InvoiceList({ config }) {
  useDocumentTitle('Invoice List');
  const dispatch = useDispatch();
  const { invoices, currentPage, totalDocuments } = useSelector((state) => state.invoices);
  const { filters, title, api, columns, actions, leadingActions, documentStatus } = config;
  const [selectedFilter, setSelectedFilter] = useState(filters.length > 0 ? filters[0].options[0].key : '');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(markInvoiceCreated(false));
    dispatch(fetchInvoicesRequest({ url: api.url, page: 1, limit: 10 }));
  }, [dispatch, api.url]);

  const handleChangePage = (event, newPage) => {
    dispatch(fetchInvoicesRequest({ url: api.url, page: newPage + 1, limit: 10 }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(fetchInvoicesRequest({ url: api.url, page: 1, limit: newLimit }));
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    const searchBy = event.target.value;
    const query = searchTerm ? `&${searchBy}=${searchTerm}` : '';
    dispatch(
      fetchInvoicesRequest({
        url: `${api.url}${query}`,
        page: 1,
        limit: 10,
      }),
    );
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    const query = searchTerm ? `&${selectedFilter}=${searchTerm}` : '';
    if (event.key === 'Enter') {
      dispatch(
        fetchInvoicesRequest({
          url: `${api.url}${query}`,
          page: 1,
          limit: 10,
        }),
      );
    }
  };

  return (
    <>
      <TopBar title={title}>
        <LeadingActions actions={leadingActions} />
      </TopBar>
      <InvoiceFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <CustomTable
        columns={columns}
        filteredData={invoices}
        documentStatus={documentStatus}
        currentPage={currentPage}
        totalCount={totalDocuments}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={InvoiceActions}
        childComponentProps={{ actions }}
      />
    </>
  );
}

export default InvoiceList;
