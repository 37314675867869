import React, { useState } from 'react';
import { MenuItem, Snackbar, Alert } from '@mui/material';
import html2pdf from 'html2pdf.js';
import { useSelector } from 'react-redux';
import formatCurrencyWords from '../../utils/formatCurrencyWords';
import nunjucks from 'nunjucks';

const DownloadDocumentAction = ({ action, documentType, documentContent }) => {
  const pdfTemplates = useSelector((state) => state.pdfTemplates.pdfTemplates);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const downloadPdf = async () => {
    setSnackbarMessage('Downloading PDF...');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);

    try {
      const template = pdfTemplates.find((t) => t.document_type === documentType);

      if (!template) {
        setSnackbarMessage(`${documentType} template not found`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const decodedTemplate = atob(template.template);
      documentContent = {
        ...documentContent,
        total_in_words: formatCurrencyWords(documentContent.grand_total, documentContent.document_currency_code),
      };
      const filledTemplate = nunjucks.renderString(decodedTemplate, documentContent);

      const container = document.createElement('div');

      container.innerHTML = filledTemplate;

      const opt = {
        margin: 0.25,
        filename: `${documentContent?.document_number}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      await html2pdf().from(container).set(opt).save();
      setSnackbarMessage('PDF downloaded successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to download PDF');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <MenuItem key={action.label} onClick={downloadPdf}>
        {action.label}
      </MenuItem>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DownloadDocumentAction;
