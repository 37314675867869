import { takeLatest, call, put } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import {
  fetchSupplierById,
  fetchSupplierByIdFailure,
  fetchSupplierByIdSuccess,
  fetchSuppliersFailure,
  fetchSuppliersRequest,
  fetchSuppliersSuccess,
  createSupplierRequest,
  createSupplierSuccess,
  createSupplierFailure,
  editSupplierRequest,
  editSupplierSuccess,
  editSupplierFailure,
} from './suppliersSlice';

function* fetchSuppliersSaga(action) {
  try {
    const { url, page, limit } = action.payload;
    const params = {
      page,
      limit,
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchSuppliersSuccess(response.data));
  } catch (error) {
    yield put(fetchSuppliersFailure(error.message));
  }
}

function* fetchSupplierByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/suppliers/${action.payload}`);
    yield put(fetchSupplierByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchSupplierByIdFailure(error.message));
  }
}

function* editSupplierRequestSaga(action) {
  try {
    const { _id, __v, ...sanitizedPayload } = action.payload;
    const response = yield call(apiClient.put, `/suppliers/${sanitizedPayload.profile_id}`, sanitizedPayload);
    yield put(editSupplierSuccess(response.data));
  } catch (error) {
    yield put(editSupplierFailure(error.response.data.error));
  }
}

function* createSupplierSaga(action) {
  try {
    const response = yield call(apiClient.post, '/suppliers', action.payload);
    yield put(createSupplierSuccess(response.data));
  } catch (error) {
    yield put(createSupplierFailure(error.response.data.errors));
  }
}

function* suppliersSaga() {
  yield takeLatest(fetchSuppliersRequest.type, fetchSuppliersSaga);
  yield takeLatest(fetchSupplierById.type, fetchSupplierByIdSaga);
  yield takeLatest(createSupplierRequest.type, createSupplierSaga);
  yield takeLatest(editSupplierRequest.type, editSupplierRequestSaga);
}

export default suppliersSaga;
