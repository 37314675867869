import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DetailsPageActions = ({ actions }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleAction = (action) => {
    switch (action.action) {
      case 'back':
        return navigate(action.target);
      default:
        console.log('Unknown action');
    }
  };

  return (
    <>
      {actions &&
        actions.map((action, index) => (
          <Button
            key={index}
            variant="contained"
            color={action.color || 'primary'}
            onClick={() => handleAction(action)}
            className="action-button"
            sx={{ marginLeft: theme.spacing(1) }}
          >
            {action.label}
          </Button>
        ))}
    </>
  );
};

export default DetailsPageActions;
