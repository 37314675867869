import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../components/CustomTable';
import CustomerListFilters from '../components/CustomerListFilters';
import CustomerActionsMenu from '../components/CustomerActionsMenu';
import TopBar from '../../../components/TopBar';
import { fetchCustomersRequest, markCustomerCreated, markCustomerEdited } from '../../../api/customers/customersSlice';
import CustomerListPageActions from '../components/CustomerListPageActions';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const filters = [
  {
    type: 'search',
    options: [
      {
        key: 'name',
        placeholder: 'Name',
      },
      {
        key: 'email',
        placeholder: 'Email',
      },
    ],
  },
];

const columns = [
  {
    columnName: 'Name / Company Name',
    mappingField: 'name',
  },
  {
    columnName: 'Contact Info',
    mappingField: 'email',
  },
  {
    columnName: 'Registration Number',
    mappingField: 'registration_number',
  },
  {
    columnName: 'Country',
    mappingField: 'country_code',
  },
  {
    columnName: 'Actions',
    mappingField: 'actions',
  },
];

function CustomerListPage() {
  useDocumentTitle('Customer List');
  const dispatch = useDispatch();
  const { customers, currentPage, totalDocuments, error } = useSelector((state) => state.customers);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const [selectedFilter, setSelectedFilter] = useState(filters.length > 0 ? filters[0].options[0].key : '');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(markCustomerCreated(false));
    dispatch(markCustomerEdited(false));
    dispatch(fetchCustomersRequest({ url: '/customers', page: 1, limit: 10 }));
  }, [dispatch]);

  if (error) return <div>Error: {error}</div>;

  const handleChangePage = (event, newPage) => {
    dispatch(
      fetchCustomersRequest({
        url: '/customers',
        page: newPage + 1,
        limit: 10,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(fetchCustomersRequest({ url: '/customers', page: 1, limit: newLimit }));
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    const searchBy = event.target.value;
    const query = searchTerm ? `?${searchBy}=${searchTerm}` : '';
    dispatch(
      fetchCustomersRequest({
        url: `/customers${query}`,
        page: 1,
        limit: 10,
      }),
    );
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    const query = searchTerm ? `?${selectedFilter}=${searchTerm}` : '';
    if (event.key === 'Enter') {
      dispatch(
        fetchCustomersRequest({
          url: `/customers${query}`,
          page: 1,
          limit: 10,
        }),
      );
    }
  };

  const getCountryName = (code) => {
    const country = countryCodes.find((country) => country.Code === code);
    return country ? country.Country : code;
  };
  return (
    <>
      <TopBar title="Customers">
        <CustomerListPageActions />
      </TopBar>
      <CustomerListFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <CustomTable
        columns={columns}
        filteredData={customers?.map((profile) => ({
          ...profile,
          country_code: getCountryName(profile.country_code),
        }))}
        currentPage={currentPage}
        totalCount={totalDocuments}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={CustomerActionsMenu}
        childComponentProps={[]}
      />
    </>
  );
}

export default CustomerListPage;
