import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  useTheme,
  Collapse,
  Divider,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';

const iconMap = {
  'business-profiles': <SettingsIcon fontSize="small" sx={{ color: 'white' }} />,
  documents: <DescriptionIcon fontSize="small" sx={{ color: 'white' }} />,
  customers: <SupervisorAccountIcon fontSize="small" sx={{ color: 'white' }} />,
  suppliers: <BusinessIcon fontSize="small" sx={{ color: 'white' }} />,
  'self-billed-documents': <DescriptionOutlinedIcon fontSize="small" sx={{ color: 'white' }} />,
};

export default function Sidebar({ resources = {} }) {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState({});

  useEffect(() => {
    Object.entries(resources).forEach(([key, value]) => {
      if (value.features) {
        value.features.forEach((feature) => {
          if (location.pathname === `/${feature}`) {
            setOpen((prevOpen) => ({ ...prevOpen, [key]: true }));
          }
        });
      } else if (location.pathname === `/${key}`) {
        setOpen((prevOpen) => ({ ...prevOpen, [key]: true }));
      }
    });
  }, [location.pathname, resources]);

  const handleClick = (key) => {
    setOpen((prevOpen) => ({ ...prevOpen, [key]: !prevOpen[key] }));
  };

  const formatFeatureName = (feature) => {
    return feature.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      sx={{
        width: '18%',
        '& .MuiDrawer-paper': {
          width: '18%',
          boxSizing: 'border-box',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      }}
    >
      <Toolbar />
      <List>
        {Object.entries(resources).map(([key, value]) => (
          <React.Fragment key={key}>
            {value.features ? (
              <>
                <ListItem onClick={() => handleClick(key)}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{iconMap[key]}</ListItemIcon>
                  <ListItemText primary={value.name} />
                  {open[key] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open[key]} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      backgroundColor: theme.palette.secondaryBackground.main,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {value.features.map((feature) => {
                      const formattedFeature = formatFeatureName(feature);
                      const isSelected = location.pathname === `/${feature}`;
                      return (
                        <ListItem
                          key={feature}
                          component={Link}
                          to={`/${feature}`}
                          sx={{
                            backgroundColor: isSelected ? theme.palette.sidebar.main : 'inherit',
                            padding: theme.spacing(1.25, 2),
                            paddingLeft: theme.spacing(7),
                            color: isSelected ? theme.palette.common.black : theme.palette.common.white,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <ListItemText primary={formattedFeature} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            ) : (
              <>
                {key === 'suppliers' && (
                  <>
                    <Divider sx={{ backgroundColor: theme.palette.common.white }} />
                  </>
                )}
                <ListItem
                  component={Link}
                  to={`/${key}`}
                  sx={{
                    backgroundColor: location.pathname.includes(key) ? theme.palette.sidebar.main : 'inherit',
                    padding: theme.spacing(1.25, 2),
                    color: location.pathname.includes(key) ? theme.palette.common.black : theme.palette.common.white,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>{iconMap[key]}</ListItemIcon>
                  <ListItemText primary={formatFeatureName(value.name)} />
                </ListItem>
              </>
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}
