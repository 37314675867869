import React, { useEffect, useState } from 'react';
import { Grid, Paper, Button, useTheme, FormControl, TextField, InputLabel } from '@mui/material';
import CustomStringInput from '../../../../components/CustomStringInput';
import CustomSelectInput from '../../../../components/CustomSelectInput';
import { DeleteButton } from '../../../../components/DeleteButton';
import { formatDecimal } from '../../../../utils/formatDecimal';

const requiredFields = ['description', 'item_code', 'unit_of_measure', 'tax_category_code'];

export default function MYSItemDetails({ items, setItems, itemCodes, taxTypes, uomCodes }) {
  const theme = useTheme();
  const [inputValues, setInputValues] = useState([]);
  const [errors, setErrors] = useState([]);
  const visibleItemDetails = inputValues.filter((item) => item.visible);

  useEffect(() => {
    const initialValues = items.map((item) => {
      return {
        ...item,
        discount_type: 'number',
        visible: true, // Add visible property
        issuedQty: item.quantity,
        issuedUnitRate: item.unit_rate,
      };
    });
    setInputValues(initialValues);
  }, []);

  const validateItem = (item) => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!item[field]) {
        newErrors[field] = `${field.replace(/_/g, ' ')} is required`;
      }
    });
    return newErrors;
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...inputValues];
    const updatedErrors = [...errors];
    const currentItem = updatedItems[index];

    if (field === 'quantity' && value > currentItem.issuedQty) {
      value = currentItem.issuedQty;
      updatedErrors[index] = 'Quantity cannot exceed issued quantity';
    }

    if (field === 'unit_rate' && value > currentItem.issuedUnitRate) {
      value = currentItem.issuedUnitRate;
    }

    if (field === 'unit_rate') {
      value = formatDecimal(value, 4);
    } else if (['discount_amount', 'tax_rate', 'quantity', 'tax_amount', 'total_amount'].includes(field)) {
      value = formatDecimal(value, 2);
    }

    updatedItems[index][field] = value;

    if (requiredFields.includes(field)) {
      if (value.trim()) {
        delete updatedErrors[index]?.[field];
      } else {
        updatedErrors[index] = {
          ...updatedErrors[index],
          [field]: `${field.replace(/_/g, ' ')} is required`,
        };
      }
    }

    if (['discount_amount', 'discount_type', 'tax_rate', 'quantity', 'unit_rate'].includes(field)) {
      updatedItems[index] = recalculateAmounts(updatedItems[index]);
    }

    setInputValues(updatedItems);
    setErrors(updatedErrors);
  };

  const recalculateAmounts = (item) => {
    const baseAmount = item.quantity * item.unit_rate;
    const discountAmount =
      item.discount_type === 'percentage' ? (baseAmount * item.discount_amount) / 100 : item.discount_amount;
    const netAmount = baseAmount - discountAmount;
    const taxAmount = (netAmount * (item.tax_rate || 0)) / 100;
    const totalAmount = netAmount + taxAmount;

    return {
      ...item,
      discount_amount: Number(item.discount_amount || 0),
      unit_rate: Number(item.unit_rate || 0),
      quantity: Number(item.quantity || 0),
      tax_rate: Number(item.tax_rate || 0),
      base_amount: Number(baseAmount.toFixed(2)),
      net_amount: Number(netAmount.toFixed(2)),
      tax_amount: Number(taxAmount.toFixed(2)),
      total_amount: Number(totalAmount.toFixed(2)),
    };
  };

  const handleRemoveItem = (index) => {
    if (visibleItemDetails.length > 1) {
      const updatedItems = [...inputValues];
      updatedItems[index].visible = false;
      setInputValues(updatedItems);
    }
  };

  const handleRestoreItem = (index) => {
    const updatedItems = [...inputValues];
    updatedItems[index].visible = true;
    setInputValues(updatedItems);
  };

  const handleBlur = (index) => {
    const updatedItems = [...inputValues];
    const item = updatedItems[index];

    if (item.discount_type === 'percentage') {
      const baseAmount = item.quantity * item.unit_rate;
      item.discount_amount = (baseAmount * parseFloat(item.discount_amount)) / 100;
    } else {
      item.discount_amount = parseFloat(item.discount_amount);
    }

    // Change type back to number
    item.discount_type = 'number';

    updatedItems[index] = recalculateAmounts(item);
    setInputValues(updatedItems);

    const itemErrors = validateItem(item);
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = itemErrors;
      return newErrors;
    });
  };

  useEffect(() => {
    setItems(visibleItemDetails);
  }, [inputValues, setItems, visibleItemDetails]);

  return (
    <>
      {inputValues.map((item, index) => {
        return item.visible ? (
          <Paper
            key={index}
            elevation={3}
            sx={{
              padding: '16px',
              marginBottom: '12px',
              backgroundColor: theme.palette.action.hover,
              borderRadius: '8px',
              position: 'relative',
            }}
          >
            <DeleteButton onClick={() => handleRemoveItem(index)} disabled={visibleItemDetails.length === 1}>
              X
            </DeleteButton>

            <Grid container spacing={2} alignItems="center">
              <CustomStringInput
                field={{
                  label: 'Item Description',
                  key: 'description',
                  required: true,
                  size: 3,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'description', e.target.value)}
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
                readOnly={true}
              />

              <CustomStringInput
                field={{
                  label: 'Qty',
                  key: 'quantity',
                  required: true,
                  size: 1,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
                type="number"
              />

              <CustomStringInput
                field={{
                  label: 'Rate',
                  key: 'unit_rate',
                  required: true,
                  size: 1,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'unit_rate', e.target.value)}
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
                type="number"
              />

              <CustomSelectInput
                field={{
                  label: 'Discount Type',
                  key: 'discount_type',
                  required: true,
                  size: 2,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'discount_type', e.target.value)}
                options={[
                  { value: 'number', label: 'Number' },
                  { value: 'percentage', label: 'Percentage' },
                ]}
                labelKey="label"
                valueKey="value"
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
              />

              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ color: 'black', fontSize: '0.875rem', mb: -1.6 }}>
                  Tax Code:<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <FormControl margin="normal">
                  <TextField
                    value={taxTypes.find((option) => option.Code === item.tax_category_code || '')?.Description || ''}
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: '0.875rem' }}
                    aria-readonly={true}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                  />
                </FormControl>
              </Grid>

              <CustomStringInput
                field={{
                  label: 'Total Tax',
                  key: 'tax_amount',
                  required: false,
                  size: 3,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'tax_amount', e.target.value)}
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
                readOnly={true}
              />
            </Grid>

            <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
              <Grid item xs={12} sm={3}>
                <InputLabel sx={{ color: 'black', fontSize: '0.875rem', mb: -1.6 }}>
                  Item Code:<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    value={itemCodes.find((option) => option.Code === item.item_code || '')?.Description || ''}
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: '0.875rem' }}
                    aria-readonly={true}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ color: 'black', fontSize: '0.875rem', mb: -1.6 }}>
                  Unit of Measure:<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    value={uomCodes.find((option) => option.Code === item.unit_of_measure || '')?.Name || ''}
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: '0.875rem' }}
                    aria-readonly={true}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                  />
                </FormControl>
              </Grid>

              <CustomStringInput
                field={{
                  label: 'Discount',
                  key: 'discount_amount',
                  required: false,
                  size: 2,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'discount_amount', e.target.value)}
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
                type="number"
              />

              <CustomStringInput
                field={{
                  label: 'Tax Rate',
                  key: 'tax_rate',
                  required: false,
                  size: 2,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'tax_rate', e.target.value)}
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
                type="number"
                readOnly={true}
              />

              <CustomStringInput
                field={{
                  label: 'Total Amount',
                  key: 'total_amount',
                  required: false,
                  size: 3,
                }}
                formData={item}
                handleChange={(e) => handleItemChange(index, 'total_amount', e.target.value)}
                errors={errors[index] || {}}
                onBlur={() => handleBlur(index)}
                readOnly={true}
              />
            </Grid>
          </Paper>
        ) : (
          <Button
            color="success"
            key={index}
            sx={{ display: 'flex', justifyContent: 'center' }}
            onClick={() => handleRestoreItem(index)}
          >
            + Put Item {index + 1} ({item.description}) Back
          </Button>
        );
      })}
    </>
  );
}
