import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CustomerListPageActions() {
  const navigate = useNavigate();

  const handleCreateAction = () => {
    return navigate('/customers/create');
  };

  return (
    <>
      <Button key="add-new-customer" variant="contained" color="primary" onClick={handleCreateAction}>
        + Create New Customer
      </Button>
    </>
  );
}

export default CustomerListPageActions;
