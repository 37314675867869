import BusinessProfileForm from '../components/BusinessProfileForm';
import {
  cancelBusinessProfileCreate,
  createBusinessProfileRequest,
} from '../../../api/businessProfiles/businessProfilesSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const initialData = {
  name: '',
  company_name: '',
  company_name_in_local: '',
  email: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  country_code: '',
  state: '',
  postal_code: '',
  phone: '',
  registration_type: '',
  registration_number: '',
  tin: '',
  sst_registration_number: '-',
  tourism_tax_registration_number: '-',
  msic_code: '',
};

export default function CreateBusinessProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isBusinessProfileCreated } = useSelector((state) => state.businessProfiles);

  useEffect(() => {
    if (isBusinessProfileCreated) {
      navigate('/business-profiles');
    }
  }, [isBusinessProfileCreated, navigate]);

  const handleSubmit = (formData) => {
    formData.company_name = formData.company_name ? formData.company_name : formData.name;
    dispatch(createBusinessProfileRequest(formData));
  };

  function handleCancel() {
    dispatch(cancelBusinessProfileCreate());
    return navigate('/business-profiles');
  }

  return (
    <BusinessProfileForm
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      initialData={initialData}
    ></BusinessProfileForm>
  );
}
