import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoicesRequest } from '../../api/invoices/invoicesSlice';

function LeadingActions({ actions }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { invoices, totalDocuments } = useSelector((state) => state.invoices);
  const [action, setAction] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    dispatch(
      fetchInvoicesRequest({
        url: '/invoices?document_type=11&status=active',
        page: 1,
        limit: totalDocuments,
      }),
    );
  }, [dispatch, totalDocuments]);

  const handleAction = (action) => {
    switch (action.action) {
      case 'create':
        setOpen(true);
        setAction(action);
        break;
      default:
        console.log('Unknown action');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAction(null);
  };

  const handleSelectInvoice = (event, value) => {
    setSelectedInvoice(value);
  };

  const handleContinue = () => {
    if (selectedInvoice) {
      navigate(action.target, { state: { invoiceData: selectedInvoice } });
    }
  };

  return (
    <>
      {actions.map((action, index) => (
        <Button key={index} variant="contained" color="primary" onClick={() => handleAction(action)}>
          {action.label}
        </Button>
      ))}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Invoice</DialogTitle>
        <Divider />
        <DialogContent>Select an invoice from the dropdown to continue.</DialogContent>
        <DialogContent>
          <Autocomplete
            options={invoices}
            getOptionLabel={(option) =>
              `${option?.document_number} | ${option?.document_date} | ${option?.grand_total} | ${option?.billed_by.name}`
            }
            renderInput={(params) => <TextField {...params} label="Select Self Billed Invoice" variant="outlined" />}
            onChange={handleSelectInvoice}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleContinue} color="primary" variant="contained" disabled={!selectedInvoice}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LeadingActions;
