import React from 'react';
import { Grid, FormControl, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchFilter = ({ filter, selectedFilter, onFilterChange, searchTerm, onSearchChange, onSearchKeyPress }) => {
  return (
    <>
      <Grid item key={filter.type}>
        <FormControl sx={{ padding: '1px', minWidth: 120 }}>
          <Select
            value={selectedFilter}
            onChange={onFilterChange}
            displayEmpty
            size="small"
            sx={{ fontSize: '0.875rem', padding: '1px' }}
            variant="outlined"
          >
            {filter.options.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.placeholder}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sx={{ padding: '1px' }}>
        <TextField
          fullWidth
          placeholder="Search..."
          value={searchTerm}
          size="small"
          onChange={onSearchChange}
          onKeyDown={onSearchKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { fontSize: '0.875rem', padding: '1px' },
          }}
          sx={{ width: '150px', padding: '1px' }}
        />
      </Grid>
    </>
  );
};

export default SearchFilter;
