import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  fetchInvoicesFailure,
  fetchInvoiceById,
  fetchInvoiceByIdSuccess,
  fetchInvoiceByIdFailure,
  createInvoiceRequest,
  createInvoiceSuccess,
  createInvoiceFailure,
  cancelInvoiceRequest,
  cancelInvoiceSuccess,
  cancelInvoiceFailure,
} from './invoicesSlice';
import { apiClient } from '../../utils/axios';

function* fetchInvoicesSaga(action) {
  try {
    const { url, page, limit } = action.payload;
    const params = {
      page,
      limit,
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchInvoicesSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoicesFailure(error.message));
  }
}

function* fetchInvoiceByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/invoices/${action.payload}`);
    yield put(fetchInvoiceByIdSuccess({ invoice: response.data }));
  } catch (error) {
    yield put(fetchInvoiceByIdFailure(error.message));
  }
}

function* createInvoiceSaga(action) {
  try {
    const response = yield call(apiClient.post, '/invoices', action.payload);
    yield put(createInvoiceSuccess(response.data));
  } catch (error) {
    yield put(createInvoiceFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* cancelInvoiceSaga(action) {
  try {
    const { url, reason } = action.payload;
    const data = { reason };
    const response = yield call(apiClient.put, url, data);
    yield put(cancelInvoiceSuccess(response.data));
  } catch (error) {
    yield put(cancelInvoiceFailure(JSON.stringify(error.response?.data?.errors)));
  }
}

function* invoiceSaga() {
  yield takeEvery(fetchInvoicesRequest.type, fetchInvoicesSaga);
  yield takeEvery(fetchInvoiceById.type, fetchInvoiceByIdSaga);
  yield takeLatest(createInvoiceRequest.type, createInvoiceSaga);
  yield takeLatest(cancelInvoiceRequest.type, cancelInvoiceSaga);
}

export default invoiceSaga;
