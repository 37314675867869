import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currencies: [],
  loading: false,
  error: null,
};

const currencySlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    fetchCurrenciesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCurrenciesSuccess(state, action) {
      state.loading = false;
      state.currencies = action.payload;
    },
    fetchCurrenciesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchCurrenciesRequest, fetchCurrenciesSuccess, fetchCurrenciesFailure } = currencySlice.actions;

export default currencySlice.reducer;
