import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uomCodes: [],
  loading: false,
  error: null,
};

const uomCodesSlice = createSlice({
  name: 'uomCodes',
  initialState,
  reducers: {
    fetchUomCodesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUomCodesSuccess(state, action) {
      state.loading = false;
      state.uomCodes = action.payload;
    },
    fetchUomCodesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchUomCodesRequest, fetchUomCodesSuccess, fetchUomCodesFailure } = uomCodesSlice.actions;

export default uomCodesSlice.reducer;
