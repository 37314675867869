import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDebitNoteById } from '../../api/debitNotes/debitNotesSlice';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import '../../styles/DocumentDetails.css';
import CustomTable from '../../components/CustomTable';
import TopBar from '../../components/TopBar';
import DetailsPageActions from './details/DetailsPageAction';
import formatCurrencyWords from '../../utils/formatCurrencyWords';
import ProfileCard from '../common/ProfileCard';
import SummaryCard from '../common/SummaryCard';
import InfoCard from '../common/InfoCard';
import { getCountryName, getStateName, getTaxCode } from '../../utils/helper';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import IrbmStatusCard from '../common/IrbmStatusCard';

export default function DebitNoteDetails({ config }) {
  useDocumentTitle('Debit Note Details');
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const taxCodes = useSelector((state) => state.taxTypes.taxTypes);
  const debitNote = useSelector((state) => state.debitNotes.selectedDebitNote);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const states = useSelector((state) => state.states.states);
  const theme = useTheme();

  useEffect(() => {
    if (document_id) {
      dispatch(fetchDebitNoteById(document_id));
    }
  }, [dispatch, document_id]);

  const {
    debitNoteSection,
    itemSection,
    actions,
    title,
    sellerSection,
    buyerSection,
    paymentSection,
    summarySection,
    grandTotalInWords,
  } = config;

  return (
    <Box>
      <TopBar title={title}>
        <DetailsPageActions actions={actions} />
      </TopBar>

      <Paper className="invoice-details-paper">
        <IrbmStatusCard document={debitNote} />

        {debitNote && <InfoCard document={debitNote} data={debitNoteSection} />}

        <Grid
          container
          spacing={2}
          className="vendor-biller-payment-container"
          sx={{ marginTop: theme.spacing(1), alignItems: 'stretch' }}
        >
          {debitNote && (
            <Grid item xs={12} md={debitNote.payment ? 4 : 6}>
              <ProfileCard
                title={sellerSection.title}
                subtitle={sellerSection.subtitle}
                fields={sellerSection.fields}
                document={{
                  ...debitNote,
                  billed_by: {
                    ...debitNote.billed_by,
                    state: getStateName(debitNote?.billed_by.state, states),
                    country_code: getCountryName(debitNote?.billed_by.country_code, countryCodes),
                  },
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Grid>
          )}
          {debitNote && (
            <Grid item xs={12} md={debitNote?.payment ? 4 : 6}>
              <ProfileCard
                title={buyerSection.title}
                subtitle={buyerSection.subtitle}
                fields={buyerSection.fields}
                document={{
                  ...debitNote,
                  billed_to: {
                    ...debitNote.billed_to,
                    state: getStateName(debitNote?.billed_to.state, states),
                    country_code: getCountryName(debitNote?.billed_to.country_code, countryCodes),
                  },
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Grid>
          )}
          {debitNote && debitNote?.payment && (
            <Grid item xs={12} md={4}>
              <ProfileCard
                title={paymentSection.title}
                subtitle={paymentSection.subtitle}
                fields={paymentSection.fields}
                document={debitNote}
              />
            </Grid>
          )}
        </Grid>

        <Box className="item-details-container">
          <Typography variant="h6" gutterBottom>
            Item Details
          </Typography>

          {debitNote && (
            <CustomTable
              columns={itemSection}
              filteredData={debitNote?.item_details?.map((item) => ({
                ...item,
                tax_category_code: getTaxCode(item.tax_category_code, taxCodes),
              }))}
              currentPage={1}
              totalCount={0}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
              actions={[]}
              isPaginationEnabled={false}
            />
          )}
        </Box>

        <SummaryCard rows={summarySection} document={debitNote} />

        <hr className="summary-divider" />

        {debitNote && grandTotalInWords && (
          <Box className="invoice-footer">
            <div className="invoice-summary-item">
              <span className="invoice-summary-label">{grandTotalInWords.label}</span>
              <span className="invoice-summary-value invoice-summary-value-bold">
                {formatCurrencyWords(debitNote.grand_total, debitNote.document_currency_code)}
              </span>
            </div>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
