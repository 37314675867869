import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GoToEditActionMenuItem = ({ customer }) => {
  const navigate = useNavigate();

  const onClickingDetails = () => {
    navigate('/customers/:id/edit'.replace(':id', customer.profile_id));
  };
  return (
    <>
      <MenuItem key="edit" onClick={onClickingDetails}>
        Edit
      </MenuItem>
    </>
  );
};
