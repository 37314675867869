import React, { useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { get } from 'lodash';
import { GoToDetailsAction } from '../common/GoToDetailsAction';
import DownloadDocumentAction from '../common/DownloadDocumentAction';
import DownloadXMLAction from '../common/DownloadXMLAction';
import CancelDocumentAction from '../refunds/actions/CancelDocumentAction';
import { GoToEditForm } from '../common/GoToEditForm';

const SBInvoiceActions = ({ actions: configuredActions, row, documentStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActions = (actions) => {
    return actions.map((action, index) => {
      switch (action.action) {
        case 'details':
          return <GoToDetailsAction key={`details-${index}`} action={action} document={row} />;
        case 'download-pdf':
          if (get(row, documentStatus.key) === 'Valid') {
            return (
              <DownloadDocumentAction
                key={`download-pdf-${index}`}
                action={action}
                documentContent={row}
                documentType="invoice"
              />
            );
          }
          return null;
        case 'download-xml':
          if (get(row, documentStatus.key) === 'Valid') {
            return (
              <DownloadXMLAction
                key={`download-xml-${index}`}
                base64XML={get(row, action.key)}
                documentNumber={row?.document_number}
                label={action.label}
              />
            );
          }
          return null;
        case 'cancel':
          if (get(row, documentStatus.key) === 'Valid') {
            return <CancelDocumentAction key={`cancel-${index}`} action={action} documentId={row?.document_id} />;
          }
          return null;
        case 'edit':
          if (
            get(row, 'status') === 'active' &&
            (get(row, documentStatus.key) === 'Invalid' || get(row, documentStatus.key) === 'Cancelled')
          ) {
            return <GoToEditForm key={`edit-${index}`} action={action} document={row} />;
          }
          return null;
        default:
          console.log('Unknown action');
          return null;
      }
    });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.2)',
            },
          },
        }}
      >
        {getActions(configuredActions)}
      </Menu>
    </>
  );
};

export default SBInvoiceActions;
