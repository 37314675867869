import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { get } from 'lodash';

const ProfileCard = ({ title, subtitle, fields, document }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        {title}
      </Typography>

      {subtitle && (
        <Typography
          variant="subtitle1"
          sx={{
            color: theme.palette.text.disabled,
            marginBottom: theme.spacing(1.5),
          }}
        >
          {subtitle}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: theme.spacing(1.25),
        }}
      >
        {fields.map((field, index) => (
          <Grid
            container
            key={field.label}
            sx={{
              backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit',
            }}
          >
            <Grid item fontSize="0.90rem" xs={4}>
              {field.label}:
            </Grid>
            <Grid
              item
              xs={5}
              fontSize="0.90rem"
              sx={{
                maxWidth: 300,
                wordWrap: 'break-word',
                marginLeft: theme.spacing(1),
              }}
            >
              {field.label === 'Address'
                ? `${get(document, field.key ? `${field.key}.address1` : 'address1')} ${get(document, field.key ? `${field.key}.address2` : 'address2')} ${get(document, field.key ? `${field.key}.city` : 'city')} ${get(document, field.key ? `${field.key}.state` : 'state')} ${get(document, field.key ? `${field.key}.postal_code` : 'postal_code')}`
                : get(document, field.key || 'defaultKey')}
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default ProfileCard;
