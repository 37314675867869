import { takeLatest, call, put } from 'redux-saga/effects';
import { apiClient } from '../../utils/axios';
import {
  createCustomerFailure,
  createCustomerRequest,
  createCustomerSuccess,
  fetchCustomerById,
  fetchCustomerByIdFailure,
  fetchCustomerByIdSuccess,
  fetchCustomersFailure,
  fetchCustomersRequest,
  fetchCustomersSuccess,
  editCustomerRequest,
  editCustomerSuccess,
  editCustomerFailure,
} from './customersSlice';

function* fetchCustomersSaga(action) {
  try {
    const { url, page, limit } = action.payload;
    const params = {
      page,
      limit,
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchCustomersSuccess(response.data));
  } catch (error) {
    yield put(fetchCustomersFailure(error.message));
  }
}

function* fetchCustomerByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/customers/${action.payload}`);
    yield put(fetchCustomerByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchCustomerByIdFailure(error.message));
  }
}

function* editCustomerRequestSaga(action) {
  try {
    const { _id, __v, ...sanitizedPayload } = action.payload;
    const response = yield call(apiClient.put, `/customers/${sanitizedPayload.profile_id}`, sanitizedPayload);
    yield put(editCustomerSuccess(response.data));
  } catch (error) {
    yield put(editCustomerFailure(error.response.data.error));
  }
}

function* createCustomerSaga(action) {
  try {
    const response = yield call(apiClient.post, '/customers', action.payload);
    yield put(createCustomerSuccess(response.data));
  } catch (error) {
    yield put(createCustomerFailure(error.response.data.errors));
  }
}

function* customersSaga() {
  yield takeLatest(fetchCustomersRequest.type, fetchCustomersSaga);
  yield takeLatest(fetchCustomerById.type, fetchCustomerByIdSaga);
  yield takeLatest(createCustomerRequest.type, createCustomerSaga);
  yield takeLatest(editCustomerRequest.type, editCustomerRequestSaga);
}

export default customersSaga;
