import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerById } from '../../../api/customers/customersSlice';
import { Box, Paper, Typography, useTheme, Divider } from '@mui/material';
import '../../../styles/DocumentDetails.css';
import TopBar from '../../../components/TopBar';
import CustomerDetailsPageActionButtons from '../components/CustomerDetailsPageActionButtons';
import { getCountryName, getStateName } from '../../../utils/helper';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { get } from 'lodash';

export default function CustomerDetailsPage() {
  useDocumentTitle('Customer Details');
  const { profile_id } = useParams();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customers.selectedCustomer);

  const theme = useTheme();

  const states = useSelector((state) => state.states.states);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchCustomerById(profile_id));
    }
  }, [dispatch, profile_id]);

  if (!customer) return null;

  return (
    <Box>
      {customer && (
        <TopBar title="Customer Details" subTitle={customer?.profile_id}>
          <CustomerDetailsPageActionButtons />
        </TopBar>
      )}

      <Paper>
        <Box
          className="flex-info-item"
          sx={{
            margin: theme.spacing(0, 0.5, 0, 0.5),
            gap: theme.spacing(1),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
          }}
        >
          <div key="name" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Name
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'name') || '-'}
            </Typography>
          </div>
          <div key="company-name" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Company Name
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'company_name') || '-'}
            </Typography>
          </div>
          <div key="email" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Email
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'email') || '-'}
            </Typography>
          </div>
          <div key="phone" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Phone Number
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'phone') || '-'}
            </Typography>
          </div>
          <div key="company-in-local" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Company Name in Local Language
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'company_name_in_local') || '-'}
            </Typography>
          </div>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography
            variant="subtitle2"
            sx={{
              mx: 2,
              color: theme.palette.text.disabled,
              textAlign: 'center',
            }}
          >
            ADDRESS
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        <Box
          className="flex-info-item"
          sx={{
            margin: theme.spacing(0, 0.5, 0, 0.5),
            gap: theme.spacing(1),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
          }}
        >
          <div key="address1" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Address street
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'address1') || '-'}
            </Typography>
          </div>
          <div key="address2" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Address street(Additional)
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'address2') || '-'}
            </Typography>
          </div>
          <div key="city" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              City
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'city') || '-'}
            </Typography>
          </div>
          <div key="state" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              State
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {getStateName(customer.state, states)}
            </Typography>
          </div>
          <div key="country" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Country
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {getCountryName(customer.country_code, countryCodes)}
            </Typography>
          </div>
          <div key="postal_code" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Postal Code
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'postal_code') || '-'}
            </Typography>
          </div>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography
            variant="subtitle2"
            sx={{
              mx: 2,
              color: theme.palette.text.disabled,
              textAlign: 'center',
            }}
          >
            Business Identifiers
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        <Box
          className="flex-info-item"
          sx={{
            margin: theme.spacing(0, 0.5, 0, 0.5),
            gap: theme.spacing(1),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
          }}
        >
          <div key="tin" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              TIN
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'tin') || '-'}
            </Typography>
          </div>
          <div key="registration_type" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Company Registration Type
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'registration_type') || '-'}
            </Typography>
          </div>
          <div key="registration_number" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              Company Registration Number
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'registration_number') || '-'}
            </Typography>
          </div>
          <div key="sst_registration_number" className="info-card-item">
            <Typography variant="title" sx={{ color: theme.palette.text.primary }}>
              SST Registration Number
            </Typography>

            <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
              {get(customer, 'sst_registration_number') || '-'}
            </Typography>
          </div>
        </Box>
      </Paper>
    </Box>
  );
}
