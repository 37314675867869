import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GoToEditForm = ({ action, document }) => {
  const navigate = useNavigate();

  const onClickingEdit = () => {
    navigate(action.url, { state: { document } });
  };

  return (
    <MenuItem key={action.action} onClick={onClickingEdit}>
      {action.label}
    </MenuItem>
  );
};
