import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import CancelDocumentDialog from '../../../components/CancelDocumentDialog';
import { cancelRefundRequest } from '../../../api/refunds/refundsSlice';

const CancelDocumentAction = ({ action, documentId }) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const maxLength = 300;
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setReason('');
  };

  const handleReasonChange = (e) => setReason(e.target.value);

  const handleCancelDocument = () => {
    dispatch(
      cancelRefundRequest({
        url: action.api.url.replace(':id', documentId),
        reason,
      }),
    );
    handleClose();
  };

  return (
    <>
      <MenuItem key={action.action} onClick={handleOpen} style={{ color: 'red' }}>
        {action.label}
      </MenuItem>

      <CancelDocumentDialog
        open={open}
        onClose={handleClose}
        onSubmit={handleCancelDocument}
        reason={reason}
        onReasonChange={handleReasonChange}
        maxLength={maxLength}
      />
    </>
  );
};

export default CancelDocumentAction;
