import React from 'react';
import { TextField, InputLabel, Grid } from '@mui/material';

const CustomStringInput = ({ field, formData, handleChange, errors, onBlur, type, disabled, readOnly }) => {
  return (
    <Grid item xs={12} sm={field.size ? field.size : 6} key={field.key}>
      <InputLabel sx={{ color: 'black', mb: -1.5, fontSize: '0.875rem' }}>
        {field.label ? `${field.label}: ` : ''} {field.required && <span style={{ color: 'red' }}>*</span>}
      </InputLabel>
      <TextField
        placeholder={field.label}
        name={field.key}
        value={formData[field.key]}
        onChange={handleChange}
        fullWidth
        margin="normal"
        size="small"
        error={!!errors[field.key]}
        onBlur={onBlur}
        inputProps={{ maxLength: field.key === 'email' ? 320 : 300 }}
        InputProps={{
          readOnly: readOnly,
          sx: { fontSize: '0.875rem' },
        }}
        type={type ? type : 'text'}
        disabled={disabled}
      />
    </Grid>
  );
};

export default CustomStringInput;
