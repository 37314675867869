import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GoToDetailsActionMenuItem = ({ supplier }) => {
  const navigate = useNavigate();

  const onClickingDetails = () => {
    navigate('/suppliers/:id'.replace(':id', supplier.profile_id));
  };

  return (
    <MenuItem key="details" onClick={onClickingDetails}>
      Details
    </MenuItem>
  );
};
