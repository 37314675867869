import SupplierForm from '../components/SupplierForm';
import { cancelSupplierCreate, editSupplierRequest, fetchSupplierById } from '../../../api/suppliers/suppliersSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function EditSupplierPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile_id } = useParams();
  const { isSupplierEdited, selectedSupplier } = useSelector((state) => state.suppliers);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchSupplierById(profile_id));
    }
  }, [dispatch, profile_id]);

  useEffect(() => {
    if (isSupplierEdited) {
      navigate('/suppliers');
    }
  }, [isSupplierEdited, navigate]);

  const handleSubmit = (formData) => {
    const updatedFormData = {
      ...formData,
      company_name: formData.company_name || formData.name,
    };
    dispatch(editSupplierRequest(updatedFormData));
  };

  function handleCancel() {
    dispatch(cancelSupplierCreate());
    return navigate('/suppliers');
  }

  return (
    <SupplierForm onSubmit={handleSubmit} onCancel={handleCancel} initialData={selectedSupplier ?? {}}></SupplierForm>
  );
}
