import CustomerForm from '../components/CustomerForm';
import { cancelCustomerCreate, createCustomerRequest } from '../../../api/customers/customersSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const initialData = {
  name: '',
  company_name: '',
  company_name_in_local: '',
  email: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  country_code: '',
  state: '',
  postal_code: '',
  phone: '',
  registration_type: '',
  registration_number: '',
  tin: '',
  sst_registration_number: '-',
};

export default function CreateCustomersPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isCustomerCreated } = useSelector((state) => state.customers);

  useEffect(() => {
    if (isCustomerCreated) {
      navigate('/customers');
    }
  }, [isCustomerCreated, navigate]);

  const handleSubmit = (formData) => {
    formData.company_name = formData.company_name ? formData.company_name : formData.name;
    dispatch(createCustomerRequest(formData));
  };

  function handleCancel() {
    dispatch(cancelCustomerCreate());
    return navigate('/customers');
  }

  return <CustomerForm onSubmit={handleSubmit} onCancel={handleCancel} initialData={initialData}></CustomerForm>;
}
