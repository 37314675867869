import CustomerForm from '../components/CustomerForm';
import { cancelCustomerCreate, editCustomerRequest, fetchCustomerById } from '../../../api/customers/customersSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function EditCustomerPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile_id } = useParams();
  const { isCustomerEdited, selectedCustomer } = useSelector((state) => state.customers);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchCustomerById(profile_id));
    }
  }, [dispatch, profile_id]);

  useEffect(() => {
    if (isCustomerEdited) {
      navigate('/customers');
    }
  }, [isCustomerEdited, navigate]);

  const handleSubmit = (formData) => {
    const updatedFormData = {
      ...formData,
      company_name: formData.company_name || formData.name,
    };
    dispatch(editCustomerRequest(updatedFormData));
  };

  function handleCancel() {
    dispatch(cancelCustomerCreate());
    return navigate('/customers');
  }

  return (
    <CustomerForm onSubmit={handleSubmit} onCancel={handleCancel} initialData={selectedCustomer ?? {}}></CustomerForm>
  );
}
