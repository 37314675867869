import { createSlice } from '@reduxjs/toolkit';

const pdfTemplateSlice = createSlice({
  name: 'pdfTemplates',
  initialState: {
    pdfTemplates: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchPdfTemplates(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPdfTemplatesSuccess(state, action) {
      state.loading = false;
      state.pdfTemplates = action.payload;
    },
    fetchPdfTemplatesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchPdfTemplates, fetchPdfTemplatesSuccess, fetchPdfTemplatesFailure } = pdfTemplateSlice.actions;

export default pdfTemplateSlice.reducer;
