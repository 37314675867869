export const MYSConfig = {
  layout: {
    header: {
      title: 'Marmin',
    },
    sidebar: {
      'business-profiles': {
        name: 'business-profiles',
      },
      documents: {
        name: 'Documents',
        features: ['invoices', 'credit-notes', 'Refunds', 'debit-notes'],
      },
      customers: {
        name: 'Customers',
      },
      suppliers: {
        name: 'Suppliers',
      },
      'self-billed-documents': {
        name: 'Self Billed Documents',
        features: [
          'self-billed-invoices',
          'self-billed-credit-notes',
          'self-billed-refunds',
          'self-billed-debit-notes',
        ],
      },
    },
    invoices: {
      name: 'Invoices',
      list: {
        title: 'Invoices',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/invoices?document_type=01',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Invoice No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Invoice Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/invoices/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/invoices/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/invoices/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Create Invoice',
            action: 'create',
            target: '/invoices/create',
          },
        ],
      },
      create: {
        title: 'Create Invoice',
        component: 'MYSInvoiceFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/invoices',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/invoices',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Invoice Details',
        api: {
          url: '/invoice/:id',
          method: 'GET',
        },
        invoiceSection: [
          {
            label: 'Invoice No',
            key: 'document_number',
          },
          {
            label: 'Invoice Date',
            key: 'document_date',
          },
          {
            label: 'Invoice Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
            isNumeric: false,
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
            isNumeric: false,
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
            isNumeric: false,
          },
          {
            columnName: 'Total (incl. of Tax)',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/invoices',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
    'credit-notes': {
      name: 'Credit Notes',
      list: {
        title: 'Credit Notes',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Credit Note Number',
              },
              {
                key: 'invoice_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/credit-notes?document_type=02',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Credit Note No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Invoice No',
            mappingField: 'invoice_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Credit Note Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/credit-notes/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/credit-notes/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/credit-notes/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Raise Credit Note',
            action: 'create',
            target: '/credit-notes/create',
          },
        ],
      },
      create: {
        title: 'Create Credit Note',
        component: 'MYSCreditNoteFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/credit-notes',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/credit-notes',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Credit Note Details',
        api: {
          url: '/credit-notes/:id',
          method: 'GET',
        },
        creditNoteSection: [
          {
            label: 'Credit Note No',
            key: 'document_number',
          },
          {
            label: 'Invoice No',
            key: 'invoice_number',
          },
          {
            label: 'Credit Note Date',
            key: 'document_date',
          },
          {
            label: 'Credit Note Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
          },
          {
            columnName: 'Amount',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/credit-notes',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
    refunds: {
      name: 'Refunds',
      list: {
        title: 'Refunds',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Refund Number',
              },
              {
                key: 'invoice_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/refunds?document_type=04',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Refund No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Invoice No',
            mappingField: 'invoice_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Refund Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/refunds/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/refunds/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/refunds/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Raise Refund',
            action: 'create',
            target: '/refunds/create',
          },
        ],
      },
      create: {
        title: 'Raise Refund',
        component: 'MYSRefundFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/refunds',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/refunds',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Refund Details',
        api: {
          url: '/refunds/:id',
          method: 'GET',
        },
        refundSection: [
          {
            label: 'Refund No',
            key: 'document_number',
          },
          {
            label: 'Invoice No',
            key: 'invoice_number',
          },
          {
            label: 'Refund Date',
            key: 'document_date',
          },
          {
            label: 'Refund Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
          },
          {
            columnName: 'Amount',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/refunds',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
    'debit-notes': {
      name: 'Debit Notes',
      list: {
        title: 'Debit Notes',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Debit Note Number',
              },
              {
                key: 'invoice_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/debit-notes?document_type=03',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Debit Note No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Invoice No',
            mappingField: 'invoice_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Debit Note Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/debit-notes/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/debit-notes/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/debit-notes/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Raise Debit Note',
            action: 'create',
            target: '/debit-notes/create',
          },
        ],
      },
      create: {
        title: 'Create Debit Note',
        component: 'MYSDebitNoteFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/debit-notes',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/debit-notes',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Debit Note Details',
        api: {
          url: '/debit-notes/:id',
          method: 'GET',
        },
        debitNoteSection: [
          {
            label: 'Debit Note No',
            key: 'document_number',
          },
          {
            label: 'Invoice No',
            key: 'invoice_number',
          },
          {
            label: 'Debit Note Date',
            key: 'document_date',
          },
          {
            label: 'Debit Note Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
          },
          {
            columnName: 'Amount',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/debit-notes',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
    'self-billed-invoices': {
      name: 'Self Billed Invoices',
      list: {
        title: 'Self Billed Invoices',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/invoices?document_type=11',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Invoice No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Invoice Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/self-billed-invoices/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/invoices/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/self-billed-invoices/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Create Self Billed Invoice',
            action: 'create',
            target: '/self-billed-invoices/create',
          },
        ],
      },
      create: {
        title: 'Create Self Billed Invoice',
        component: 'MYSSBInvoiceFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/self-billed-invoices',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/self-billed-invoices',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Invoice Details',
        api: {
          url: '/self-billed-invoices/:id',
          method: 'GET',
        },
        invoiceSection: [
          {
            label: 'Invoice No',
            key: 'document_number',
          },
          {
            label: 'Invoice Date',
            key: 'document_date',
          },
          {
            label: 'Invoice Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
            isNumeric: false,
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
            isNumeric: false,
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
            isNumeric: false,
          },
          {
            columnName: 'Total (incl. of Tax)',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/self-billed-invoices',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
    'self-billed-credit-notes': {
      name: 'Self Billed Credit Notes',
      list: {
        title: 'Self Billed Credit Notes',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Credit Note Number',
              },
              {
                key: 'invoice_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/credit-notes?document_type=12',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Credit Note No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Invoice No',
            mappingField: 'invoice_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Credit Note Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/self-billed-credit-notes/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/credit-notes/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/self-billed-credit-notes/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Raise Self Billed Credit Note',
            action: 'create',
            target: '/self-billed-credit-notes/create',
          },
        ],
      },
      create: {
        title: 'Create Self Billed Credit Note',
        component: 'MYSSBCreditNoteFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/self-billed-credit-notes',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/self-billed-credit-notes',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Self Billed Credit Note Details',
        api: {
          url: '/self-billed-credit-notes/:id',
          method: 'GET',
        },
        creditNoteSection: [
          {
            label: 'Credit Note No',
            key: 'document_number',
          },
          {
            label: 'Invoice No',
            key: 'invoice_number',
          },
          {
            label: 'Credit Note Date',
            key: 'document_date',
          },
          {
            label: 'Credit Note Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
          },
          {
            columnName: 'Amount',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/self-billed-credit-notes',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
    'self-billed-refunds': {
      name: 'Self Billed Refunds',
      list: {
        title: 'Self Billed Refunds',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Refund Number',
              },
              {
                key: 'invoice_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/refunds?document_type=14',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Refund No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Invoice No',
            mappingField: 'invoice_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Refund Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/self-billed-refunds/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/refunds/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/self-billed-refunds/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Raise Self Billed Refund',
            action: 'create',
            target: '/self-billed-refunds/create',
          },
        ],
      },
      create: {
        title: 'Create Self Billed Refund',
        component: 'MYSSBRefundFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/self-billed-refunds',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/self-billed-refunds',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Self Billed Refund Details',
        api: {
          url: '/self-billed-refunds/:id',
          method: 'GET',
        },
        refundSection: [
          {
            label: 'Refund No',
            key: 'document_number',
          },
          {
            label: 'Invoice No',
            key: 'invoice_number',
          },
          {
            label: 'Refund Date',
            key: 'document_date',
          },
          {
            label: 'Refund Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
          },
          {
            columnName: 'Amount',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/self-billed-refunds',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
    'self-billed-debit-notes': {
      name: 'Self Billed Debit Notes',
      list: {
        title: 'Self Billed Debit Notes',
        filters: [
          {
            type: 'search',
            options: [
              {
                key: 'document_number',
                placeholder: 'Debit Note Number',
              },
              {
                key: 'invoice_number',
                placeholder: 'Invoice Number',
              },
            ],
          },
        ],
        documentStatus: {
          key: 'irbm_response.status',
        },
        api: {
          url: '/debit-notes?document_type=13',
          method: 'GET',
        },
        columns: [
          {
            columnName: 'Debit Note No',
            mappingField: 'document_number',
          },
          {
            columnName: 'Invoice No',
            mappingField: 'invoice_number',
          },
          {
            columnName: 'Customer Info',
            mappingField: 'billed_to.name',
          },
          {
            columnName: 'Debit Note Date',
            mappingField: 'document_date',
          },
          {
            columnName: 'Amount',
            mappingField: 'grand_total',
          },
          {
            columnName: 'Actions',
            mappingField: 'actions',
          },
        ],
        actions: [
          {
            label: 'Details',
            action: 'details',
            target: '/self-billed-debit-notes/:id',
          },
          {
            label: 'Download PDF',
            action: 'download-pdf',
          },
          {
            label: 'Download XML',
            action: 'download-xml',
            key: 'irbm_response.reported_invoice',
          },
          {
            label: 'Cancel Document',
            action: 'cancel',
            api: {
              url: '/debit-notes/:id/cancel',
              method: 'PUT',
            },
          },
          {
            label: 'Edit & Submit New Document',
            action: 'edit',
            url: '/self-billed-debit-notes/create',
          },
        ],
        leadingActions: [
          {
            label: '+ Raise Self Billed Debit Note',
            action: 'create',
            target: '/self-billed-debit-notes/create',
          },
        ],
      },
      create: {
        title: 'Create Self Billed Debit Note',
        component: 'MYSSBDebitNoteFormComponent',
        actions: [
          {
            label: 'Cancel',
            action: 'cancel',
            target: '/self-billed-debit-notes',
            variant: 'outlined',
          },
          {
            label: 'Save',
            action: 'save',
            target: '/self-billed-debit-notes',
            variant: 'contained',
          },
        ],
      },
      details: {
        title: 'Self Billed Debit Note Details',
        api: {
          url: '/self-billed-debit-notes/:id',
          method: 'GET',
        },
        debitNoteSection: [
          {
            label: 'Debit Note No',
            key: 'document_number',
          },
          {
            label: 'Invoice No',
            key: 'invoice_number',
          },
          {
            label: 'Debit Note Date',
            key: 'document_date',
          },
          {
            label: 'Debit Note Type',
            key: 'document_type',
          },
          {
            label: 'Payment Terms',
            key: 'payment_terms',
          },
          {
            label: 'Currency',
            key: 'document_currency_code',
          },
        ],
        itemSection: [
          {
            columnName: 'Item Code',
            mappingField: 'item_code',
          },
          {
            columnName: 'Item / Service Description',
            mappingField: 'description',
          },
          {
            columnName: 'Quantity',
            mappingField: 'quantity',
            isNumeric: true,
          },
          {
            columnName: 'Unit Rate',
            mappingField: 'unit_rate',
            isNumeric: true,
          },
          {
            columnName: 'Discount',
            mappingField: 'discount_amount',
            isNumeric: true,
          },
          {
            columnName: 'Tax',
            mappingField: 'tax',
            isNumeric: true,
          },
          {
            columnName: 'Tax Code',
            mappingField: 'tax_category_code',
          },
          {
            columnName: 'Amount',
            mappingField: 'total_amount',
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: 'Back',
            action: 'back',
            target: '/self-billed-debit-notes',
          },
        ],
        sellerSection: {
          title: 'Billed By',
          subtitle: 'Vendor Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_by.name',
            },
            {
              label: 'Company Name',
              key: 'billed_by.name',
            },
            {
              label: 'Address',
              key: 'billed_by',
            },
            {
              label: 'Country',
              key: 'billed_by.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_by.phone',
            },
            {
              label: 'Email',
              key: 'billed_by.email',
            },
            {
              label: 'CR',
              key: 'billed_by.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_by.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_by.sst_registration_number',
            },
          ],
        },
        buyerSection: {
          title: 'Billed To',
          subtitle: 'Customer Info',
          fields: [
            {
              label: 'Contact Person',
              key: 'billed_to.name',
            },
            {
              label: 'Company Name',
              key: 'billed_to.name',
            },
            {
              label: 'Address',
              key: 'billed_to',
            },
            {
              label: 'Country',
              key: 'billed_to.country_code',
            },
            {
              label: 'Phone',
              key: 'billed_to.phone',
            },
            {
              label: 'Email',
              key: 'billed_to.email',
            },
            {
              label: 'CR',
              key: 'billed_to.registration_number',
            },
            {
              label: 'TIN',
              key: 'billed_to.tin',
            },
            {
              label: 'VAT/GST No',
              key: 'billed_to.sst_registration_number',
            },
          ],
        },
        paymentSection: {
          title: 'Payment Info',
          subtitle: 'Bank Details',
          fields: [
            {
              label: 'Bank Country',
              key: 'payment.bank_country',
            },
            {
              label: 'Account Number',
              key: 'payment.ac_no',
            },
            {
              label: 'IBAN',
              key: 'payment.iban',
            },
            {
              label: 'Bank Name',
              key: 'payment.bank_name',
            },
            {
              label: 'SWIFT Code',
              key: 'payment.swift_code',
            },
            {
              label: 'Beneficiary Name',
              key: 'payment.beneficiary_name',
            },
          ],
        },
        summarySection: [
          {
            label: 'Sub Total',
            value: 'sub_total',
          },
          {
            label: 'Total Discount Amount',
            value: 'total_item_discount',
          },
          {
            label: 'Total Taxable Amount',
            value: 'total_excluding_tax',
          },
          {
            label: 'Total Tax',
            value: 'total_tax_amount',
          },
          {
            label: 'Grand Total',
            value: 'grand_total',
          },
        ],
        grandTotalInWords: {
          label: '',
        },
      },
    },
  },
};
