import { takeEvery, call, put } from 'redux-saga/effects';
import { fetchPdfTemplates, fetchPdfTemplatesSuccess, fetchPdfTemplatesFailure } from './pdfTemplateSlice';
import { apiClient } from '../../utils/axios';

function* fetchPdfTemplatesSaga() {
  try {
    const response = yield call(apiClient.get, '/pdf-templates');
    yield put(fetchPdfTemplatesSuccess(response.data));
  } catch (error) {
    yield put(fetchPdfTemplatesFailure(error.message));
  }
}

function* pdfTemplateSaga() {
  yield takeEvery(fetchPdfTemplates.type, fetchPdfTemplatesSaga);
}

export default pdfTemplateSaga;
