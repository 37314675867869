import { takeLatest, call, put } from 'redux-saga/effects';
import {
  cancelDebitNoteFailure,
  cancelDebitNoteRequest,
  cancelDebitNoteSuccess,
  createDebitNoteFailure,
  createDebitNoteRequest,
  createDebitNoteSuccess,
  fetchDebitNoteById,
  fetchDebitNoteByIdFailure,
  fetchDebitNoteByIdSuccess,
  fetchDebitNotesFailure,
  fetchDebitNotesRequest,
  fetchDebitNotesSuccess,
} from './debitNotesSlice';
import { apiClient } from '../../utils/axios';

function* fetchDebitNotesSaga(action) {
  try {
    const { url, page, limit } = action.payload;
    const params = {
      page,
      limit,
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchDebitNotesSuccess(response.data));
  } catch (error) {
    yield put(fetchDebitNotesFailure(error.message));
  }
}

function* fetchDebitNoteByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/debit-notes/${action.payload}`);
    yield put(fetchDebitNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchDebitNoteByIdFailure(error.message));
  }
}

function* createDebitNoteSaga(action) {
  try {
    const response = yield call(apiClient.post, '/debit-notes', action.payload);
    yield put(createDebitNoteSuccess(response.data));
  } catch (error) {
    yield put(createDebitNoteFailure(error.response?.data?.errors));
  }
}

function* cancelDebitNoteSaga(action) {
  try {
    const { url, reason } = action.payload;
    const data = { reason };
    const response = yield call(apiClient.put, url, data);
    yield put(cancelDebitNoteSuccess(response.data));
  } catch (error) {
    yield put(cancelDebitNoteFailure(error.response?.data?.errors));
  }
}

function* debitNotesSaga() {
  yield takeLatest(fetchDebitNotesRequest.type, fetchDebitNotesSaga);
  yield takeLatest(fetchDebitNoteById.type, fetchDebitNoteByIdSaga);
  yield takeLatest(createDebitNoteRequest.type, createDebitNoteSaga);
  yield takeLatest(cancelDebitNoteRequest.type, cancelDebitNoteSaga);
}

export default debitNotesSaga;
