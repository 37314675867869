import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';

export default function Loader() {
  const suppliersLoading = useSelector((state) => state.suppliers.loading);
  const bpLoading = useSelector((state) => state.businessProfiles.loading);
  const invoicesLoading = useSelector((state) => state.invoices.loading);
  const cnLoading = useSelector((state) => state.creditNotes.loading);
  const dnLoading = useSelector((state) => state.debitNotes.loading);
  const refundsLoading = useSelector((state) => state.refunds.loading);
  const customersLoading = useSelector((state) => state.customers.loading);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(
      [suppliersLoading, bpLoading, invoicesLoading, cnLoading, dnLoading, refundsLoading, customersLoading].some(
        (loading) => loading,
      ),
    );
  }, [suppliersLoading, bpLoading, invoicesLoading, cnLoading, dnLoading, refundsLoading, customersLoading]);

  if (isLoading) {
    return (
      <Backdrop
        open={isLoading}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
