import React from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { get } from 'lodash';

const getIrbmStatusBackgroundColor = (status) => {
  switch (status) {
    case 'Valid':
      return '#E6F4EA';
    case 'Invalid':
      return '#FEECEC';
    case 'Submitted':
      return '#E6F4FF';
    case 'Cancelled':
      return '#E9ECEF';
    default:
      return 'transparent';
  }
};

const IrbmStatusCard = ({ document }) => {
  const theme = useTheme();
  const irbmStatus = get(document, 'irbm_response.status');
  const documentUUID = get(document, 'irbm_response.document_id');
  const validationSteps = get(document, 'irbm_response.validation_results.validation_steps');
  const irbmResponse = get(document, 'irbm_response');

  const renderValidationSteps = (steps) => (
    <Box>
      {steps.map((step, index) => (
        <Box key={index} sx={{ marginBottom: theme.spacing(1) }}>
          {step.error && (
            <>
              <Typography variant="subtitle1">
                Error Code: {step.error?.code}
                {step.error?.errorCode}
              </Typography>
              {step.error.details?.map((detail, detailIndex) => (
                <Typography key={detailIndex} variant="body2">
                  {detail?.code ? <strong>{detail.code}: </strong> : ''}
                  {detail.message}
                </Typography>
              ))}
              {step.error.innerError?.map((error, errorIndex) => (
                <Typography key={errorIndex} variant="body2">
                  {error?.errorCode ? <strong>{error.errorCode}: </strong> : ''}
                  {error.error}
                </Typography>
              ))}
            </>
          )}
        </Box>
      ))}
    </Box>
  );

  const renderCancellationErrors = (errors) => (
    <Paper
      sx={{
        margin: theme.spacing(0, 0.5, 3.5, 0.5),
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#FEECEC',
        borderRadius: '4px',
      }}
    >
      <Typography fontSize="1rem" fontWeight="bold">
        Cancellation Errors:
      </Typography>
      {errors.details.map((error, index) => (
        <Box key={index}>
          <Typography>
            <strong style={{ fontWeight: '500' }}>{error.code}: </strong>
            {error.message}
          </Typography>
        </Box>
      ))}
    </Paper>
  );

  return (
    <>
      {irbmStatus && (
        <Paper
          sx={{
            margin: theme.spacing(0, 0.5, 3.5, 0.5),
            gap: theme.spacing(2),
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: getIrbmStatusBackgroundColor(irbmStatus),
            borderRadius: '4px',
          }}
        >
          <Typography>
            <strong>IRBM Status:</strong> {irbmStatus}
          </Typography>
          {documentUUID && (
            <Typography>
              <strong>Document UUID: </strong>
              {documentUUID}
            </Typography>
          )}
          {validationSteps?.length > 0 && renderValidationSteps(validationSteps)}
        </Paper>
      )}
      {irbmResponse?.cancellation_error && renderCancellationErrors(irbmResponse.cancellation_error)}
    </>
  );
};

export default IrbmStatusCard;
