import React, { useEffect } from 'react';
import { Button, Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createRefundRequest } from '../../../api/refunds/refundsSlice';

export default function CreateRefundActions({ formData, requiredFields, setErrors, actions }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRefundCreated } = useSelector((state) => state.refunds);

  useEffect(() => {
    if (isRefundCreated) {
      navigate('/self-billed-refunds');
    }
  }, [isRefundCreated, navigate]);

  const handleSubmit = async (action) => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.replace(/_/g, ' ')} is required`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      await dispatch(createRefundRequest(formData));
    }
  };

  const handleAction = (action) => {
    switch (action.action) {
      case 'save':
        return handleSubmit(action);
      case 'cancel':
        return navigate(action.target);
      default:
        console.log('Unknown action');
    }
  };

  return (
    <Box>
      {actions &&
        actions.map((action, index) => (
          <Button
            key={index}
            variant={action.variant || 'contained'}
            color={action.color || 'primary'}
            onClick={() => handleAction(action)}
            className="action-button"
            sx={{ marginLeft: theme.spacing(1) }}
          >
            {action.label}
          </Button>
        ))}
    </Box>
  );
}
