import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchRefundsRequest,
  fetchRefundsSuccess,
  fetchRefundsFailure,
  fetchRefundById,
  fetchRefundByIdSuccess,
  fetchRefundByIdFailure,
  createRefundRequest,
  createRefundFailure,
  createRefundSuccess,
  cancelRefundRequest,
  cancelRefundSuccess,
  cancelRefundFailure,
} from './refundsSlice';
import { apiClient } from '../../utils/axios';

function* fetchRefundsSaga(action) {
  try {
    const { url, page, limit } = action.payload;
    const params = {
      page,
      limit,
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchRefundsSuccess(response.data));
  } catch (error) {
    yield put(fetchRefundsFailure(error.message));
  }
}

function* fetchRefundByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/refunds/${action.payload}`);
    yield put(fetchRefundByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchRefundByIdFailure(error.message));
  }
}

function* createRefundSaga(action) {
  try {
    const response = yield call(apiClient.post, '/refunds', action.payload);
    yield put(createRefundSuccess(response.data));
  } catch (error) {
    yield put(createRefundFailure(error.response?.data?.errors));
  }
}

function* cancelRefundSaga(action) {
  try {
    const { url, reason } = action.payload;
    const data = { reason };
    const response = yield call(apiClient.put, url, data);
    yield put(cancelRefundSuccess(response.data));
  } catch (error) {
    yield put(cancelRefundFailure(error.response?.data?.errors));
  }
}

function* refundsSaga() {
  yield takeLatest(fetchRefundsRequest.type, fetchRefundsSaga);
  yield takeLatest(fetchRefundById.type, fetchRefundByIdSaga);
  yield takeLatest(createRefundRequest.type, createRefundSaga);
  yield takeLatest(cancelRefundRequest.type, cancelRefundSaga);
}

export default refundsSaga;
