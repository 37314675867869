import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function LeadingActions({ actions }) {
  const navigate = useNavigate();

  const handleAction = (action) => {
    switch (action.action) {
      case 'create':
        return navigate(action.target);
      default:
        console.log('Unknown action');
    }
  };

  return (
    <>
      {actions.map((action, index) => (
        <Button key={index} variant="contained" color="primary" onClick={() => handleAction(action)}>
          {action.label}
        </Button>
      ))}
    </>
  );
}

export default LeadingActions;
