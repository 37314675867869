import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  states: [],
  loading: false,
  error: null,
};

const statesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    fetchStatesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchStatesSuccess(state, action) {
      state.loading = false;
      state.states = action.payload;
    },
    fetchStatesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStatesRequest, fetchStatesSuccess, fetchStatesFailure } = statesSlice.actions;

export default statesSlice.reducer;
