import SupplierForm from '../components/SupplierForm';
import { cancelSupplierCreate, createSupplierRequest } from '../../../api/suppliers/suppliersSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const initialData = {
  name: '',
  company_name: '',
  company_name_in_local: '',
  email: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  country_code: '',
  state: '',
  postal_code: '',
  phone: '',
  registration_type: '',
  registration_number: '',
  tin: '',
  sst_registration_number: '-',
  tourism_tax_registration_number: '-',
  msic_code: '',
};

export default function CreateSuppliersPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isSupplierCreated } = useSelector((state) => state.suppliers);

  useEffect(() => {
    if (isSupplierCreated) {
      navigate('/suppliers');
    }
  }, [isSupplierCreated, navigate]);

  const handleSubmit = (formData) => {
    formData.company_name = formData.company_name ? formData.company_name : formData.name;
    dispatch(createSupplierRequest(formData));
  };

  function handleCancel() {
    dispatch(cancelSupplierCreate());
    return navigate('/suppliers');
  }

  return <SupplierForm onSubmit={handleSubmit} onCancel={handleCancel} initialData={initialData}></SupplierForm>;
}
