import React from 'react';
import { AppBar, Toolbar, Typography, useTheme, IconButton } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';

function SubHeader({ title, subTitle }) {
  const theme = useTheme();

  const handleCopy = () => {
    navigator.clipboard.writeText(subTitle);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar disableGutters={true}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: theme.palette.secondary.main,
          }}
        >
          {title}
        </Typography>
        {subTitle && (
          <>
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: theme.palette.primary.light,
              }}
            >
              | {subTitle}
            </Typography>
            <IconButton aria-label="copy" onClick={handleCopy} sx={{ color: theme.palette.primary.light }}>
              <ContentCopy fontSize="small" />
            </IconButton>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default SubHeader;
