import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentMeans: [],
  loading: false,
  error: null,
};

const paymentMeansSlice = createSlice({
  name: 'paymentMeans',
  initialState,
  reducers: {
    fetchPaymentTermsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPaymentTermsSuccess(state, action) {
      state.loading = false;
      state.paymentMeans = action.payload;
    },
    fetchPaymentTermsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchPaymentTermsRequest, fetchPaymentTermsSuccess, fetchPaymentTermsFailure } =
  paymentMeansSlice.actions;

export default paymentMeansSlice.reducer;
