import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function BusinessListPageActions() {
  const navigate = useNavigate();

  const handleCreateAction = () => {
    return navigate('/business-profiles/create');
  };

  return (
    <>
      <Button key="add-new-business" variant="contained" color="primary" onClick={handleCreateAction}>
        + Create New Business
      </Button>
    </>
  );
}

export default BusinessListPageActions;
