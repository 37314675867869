import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GoToDetailsActionMenuItem = ({ customer }) => {
  const navigate = useNavigate();

  const onClickingDetails = () => {
    navigate('/customers/:id'.replace(':id', customer.profile_id));
  };

  return (
    <MenuItem key="details" onClick={onClickingDetails}>
      Details
    </MenuItem>
  );
};
