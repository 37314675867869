import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GoToDetailsActionMenuItem = ({ businessProfile }) => {
  const navigate = useNavigate();

  const onClickingDetails = () => {
    navigate('/business-profiles/:id'.replace(':id', businessProfile.profile_id));
  };

  return (
    <MenuItem key="details" onClick={onClickingDetails}>
      Details
    </MenuItem>
  );
};
