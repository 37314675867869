import { call, put, takeLatest } from 'redux-saga/effects';
import { authApiClient } from '../../utils/axios';
import { loginRequest, loginSuccess, loginFailure } from './authSlice';

function* handleLogin(action) {
  try {
    const response = yield call(authApiClient.post, '/login', action.payload);
    const token = response.data.token;
    localStorage.setItem('AUTH_TOKEN', token);
    yield put(loginSuccess(response.data));
  } catch (error) {
    yield put(loginFailure(error.response?.data?.errors?.message || 'Login failed'));
  }
}

export default function* loginSaga() {
  yield takeLatest(loginRequest.type, handleLogin);
}
