import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creditNotes: [],
  currentPage: 1,
  selectedCreditNote: null,
  totalPages: 1,
  totalDocuments: 0,
  isCreditNoteCreated: false,
  isCreditNoteCancelled: false,
  loading: false,
  error: null,
};

const creditNotesSlice = createSlice({
  name: 'creditNotes',
  initialState,
  reducers: {
    fetchCreditNotesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCreditNotesSuccess(state, action) {
      state.loading = false;
      state.creditNotes = action.payload.credit_notes;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchCreditNotesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchCreditNoteById(state, action) {
      state.loading = true;
      state.error = null;
    },
    fetchCreditNoteByIdSuccess(state, action) {
      state.loading = false;
      state.selectedCreditNote = action.payload;
    },
    fetchCreditNoteByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createCreditNoteRequest(state) {
      state.loading = true;
      state.error = null;
    },
    createCreditNoteSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isCreditNoteCreated = true;
    },
    createCreditNoteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    markCreditNoteCreated(state, action) {
      state.isCreditNoteCreated = false;
    },
    cancelCreditNoteRequest(state) {
      state.loading = true;
      state.error = null;
    },
    cancelCreditNoteSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isCreditNoteCancelled = true;
    },
    cancelCreditNoteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchCreditNotesRequest,
  fetchCreditNotesSuccess,
  fetchCreditNotesFailure,
  fetchCreditNoteById,
  fetchCreditNoteByIdSuccess,
  fetchCreditNoteByIdFailure,
  createCreditNoteRequest,
  createCreditNoteSuccess,
  createCreditNoteFailure,
  markCreditNoteCreated,
  cancelCreditNoteRequest,
  cancelCreditNoteSuccess,
  cancelCreditNoteFailure,
} = creditNotesSlice.actions;

export default creditNotesSlice.reducer;
