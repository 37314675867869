import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, Typography, useTheme, Grid } from '@mui/material';
import '../../styles/DocumentDetails.css';
import CustomTable from '../../components/CustomTable';
import TopBar from '../../components/TopBar';
import InfoCard from '../common/InfoCard';
import ProfileCard from '../common/ProfileCard';
import SummaryCard from '../common/SummaryCard';
import DetailsPageActions from './details/DetailsPageAction';
import formatCurrencyWords from '../../utils/formatCurrencyWords';
import { fetchRefundById } from '../../api/refunds/refundsSlice';
import { getCountryName, getStateName, getTaxCode } from '../../utils/helper';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import IrbmStatusCard from '../common/IrbmStatusCard';

export default function SBRefundDetails({ config }) {
  useDocumentTitle('Self Billed Refund Details');
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const taxCodes = useSelector((state) => state.taxTypes.taxTypes);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const states = useSelector((state) => state.states.states);
  const refund = useSelector((state) => state.refunds.selectedRefund);
  const theme = useTheme();

  useEffect(() => {
    if (document_id) {
      dispatch(fetchRefundById(document_id));
    }
  }, [dispatch, document_id]);

  const {
    refundSection,
    itemSection,
    actions,
    title,
    sellerSection,
    buyerSection,
    paymentSection,
    summarySection,
    grandTotalInWords,
  } = config;

  return (
    <Box>
      <TopBar title={title}>
        <DetailsPageActions actions={actions} />
      </TopBar>

      <Paper className="refund-details-paper">
        <IrbmStatusCard document={refund} />

        {refund && <InfoCard document={refund} data={refundSection} />}

        <Grid container spacing={2} className="vendor-biller-payment-container" sx={{ marginTop: theme.spacing(1) }}>
          {refund && (
            <Grid item xs={12} md={refund.payment ? 4 : 6}>
              <ProfileCard
                title={sellerSection.title}
                subtitle={sellerSection.subtitle}
                fields={sellerSection.fields}
                document={{
                  ...refund,
                  billed_by: {
                    ...refund.billed_by,
                    state: getStateName(refund?.billed_by.state, states),
                    country_code: getCountryName(refund?.billed_by.country_code, countryCodes),
                  },
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Grid>
          )}
          {refund && (
            <Grid item xs={12} md={refund.payment ? 4 : 6}>
              <ProfileCard
                title={buyerSection.title}
                subtitle={buyerSection.subtitle}
                fields={buyerSection.fields}
                document={{
                  ...refund,
                  billed_to: {
                    ...refund.billed_to,
                    state: getStateName(refund?.billed_to.state, states),
                    country_code: getCountryName(refund?.billed_to.country_code, countryCodes),
                  },
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Grid>
          )}
          {refund && refund?.payment && (
            <Grid item xs={12} md={4}>
              <ProfileCard
                title={paymentSection.title}
                subtitle={paymentSection.subtitle}
                fields={paymentSection.fields}
                document={refund}
              />
            </Grid>
          )}
        </Grid>

        <Box className="item-details-container">
          <Typography variant="h6" gutterBottom>
            Item Details
          </Typography>

          {refund && (
            <CustomTable
              columns={itemSection}
              filteredData={refund?.item_details?.map((item) => ({
                ...item,
                tax_category_code: getTaxCode(item.tax_category_code, taxCodes),
              }))}
              currentPage={1}
              totalCount={0}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
              actions={[]}
              isPaginationEnabled={false}
            />
          )}
        </Box>

        <SummaryCard rows={summarySection} document={refund} />

        <hr className="summary-divider" />

        {refund && grandTotalInWords && (
          <Box className="refund-footer">
            <div className="document-summary-item">
              <span className="document-summary-label">{grandTotalInWords.label}</span>
              <span className="document-summary-value document-summary-value-bold">
                {formatCurrencyWords(refund.grand_total, refund.document_currency_code)}
              </span>
            </div>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
