import BusinessProfileForm from '../components/BusinessProfileForm';
import {
  cancelBusinessProfileCreate,
  editBusinessProfileRequest,
  fetchBusinessProfileById,
} from '../../../api/businessProfiles/businessProfilesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function EditBusinessProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile_id } = useParams();
  const { isBusinessProfileEdited, selectedBusinessProfile } = useSelector((state) => state.businessProfiles);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchBusinessProfileById(profile_id));
    }
  }, [dispatch, profile_id]);

  useEffect(() => {
    if (isBusinessProfileEdited) {
      navigate('/business-profiles');
    }
  }, [isBusinessProfileEdited]);

  const handleSubmit = (formData) => {
    const updatedFormData = {
      ...formData,
      company_name: formData.company_name || formData.name,
    };
    dispatch(editBusinessProfileRequest(updatedFormData));
  };

  function handleCancel() {
    dispatch(cancelBusinessProfileCreate());
    return navigate('/business-profiles');
  }

  return (
    <BusinessProfileForm
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      initialData={selectedBusinessProfile ?? {}}
      edit={true}
    ></BusinessProfileForm>
  );
}
