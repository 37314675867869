import { all } from 'redux-saga/effects';
import invoicesSaga from './invoices/invoicesSaga';
import loginSaga from './auth/loginSaga';
import pdfTemplateSaga from './pdfTemplates/pdfTemplateSaga';
import creditNotesSaga from './creditNotes/creditNotesSaga';
import refundsSaga from './refunds/refundsSaga';
import debitNotesSaga from './debitNotes/debitNotesSaga';
import businessProfilesSaga from './businessProfiles/businessProfilesSaga';
import constantsSaga from './constants/constantsSaga';
import customersSaga from './customers/customersSaga';
import suppliersSaga from './suppliers/suppliersSaga';

export default function* rootSaga() {
  yield all([
    invoicesSaga(),
    loginSaga(),
    pdfTemplateSaga(),
    creditNotesSaga(),
    refundsSaga(),
    debitNotesSaga(),
    businessProfilesSaga(),
    constantsSaga(),
    customersSaga(),
    suppliersSaga(),
  ]);
}
