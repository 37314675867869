import React from 'react';
import { Route } from 'react-router-dom';
import InvoiceList from '../resources/Invoices/InvoiceList';
import InvoiceDetails from '../resources/Invoices/InvoiceDetails';
import CreditNoteList from '../resources/creditNotes/CreditNoteList';
import CreditNoteDetails from '../resources/creditNotes/CreditNoteDetails';
import RefundList from '../resources/refunds/RefundList';
import RefundDetails from '../resources/refunds/RefundDetails';
import DebitNoteList from '../resources/debitNotes/DebitNoteList';
import DebitNoteDetails from '../resources/debitNotes/DebitNoteDetails';
import BusinessProfileListPage from '../resources/businessProfiles/pages/BusinessProfileListPage';
import BusinessProfileDetailsPage from '../resources/businessProfiles/pages/BusinessProfileDetailsPage';
import CustomerDetailsPage from '../resources/customers/pages/CustomerDetailsPage';
import MYSInvoiceForm from '../resources/Invoices/create/mys/MYSInvoiceForm';
import MYSCreditNoteForm from '../resources/creditNotes/create/mys/MYSCreditNoteForm';
import MYSDebitNoteForm from '../resources/debitNotes/create/mys/MYSDebitNoteForm';
import MYSRefundForm from '../resources/refunds/create/mys/MYSRefundForm';
import MYSSBInvoiceForm from '../resources/selfBilledInvoices/create/mys/MYSSBInvoiceForm';
import SBInvoiceList from '../resources/selfBilledInvoices/SBInvoiceList';
import SBInvoiceDetails from '../resources/selfBilledInvoices/SBInvoiceDetails';
import SBCreditNoteList from '../resources/selfBilledCreditNotes/SBCreditNoteList';
import SBCreditNoteDetails from '../resources/selfBilledCreditNotes/SBCreditNoteDetails';
import MYSSBCreditNoteForm from '../resources/selfBilledCreditNotes/create/mys/MYSSBCreditNoteForm';
import SBDebitNoteList from '../resources/selfBilledDebitNotes/SBDebitNoteList';
import SBDebitNoteDetails from '../resources/selfBilledDebitNotes/SBDebitNoteDetails';
import MYSSBDebitNoteForm from '../resources/selfBilledDebitNotes/create/mys/MYSSBDebitNoteForm';
import SBRefundDetails from '../resources/selfBilledRefunds/SBRefundDetails';
import SBRefundList from '../resources/selfBilledRefunds/SBRefundList';
import MYSSBRefundForm from '../resources/selfBilledRefunds/create/mys/MYSSBRefundForm';
import CreateBusinessProfilePage from '../resources/businessProfiles/pages/CreateBusinessProfilePage';
import EditBusinessProfilePage from '../resources/businessProfiles/pages/EditBusinessProfilePage';
import EditCustomerPage from '../resources/customers/pages/EditCustomerPage';
import CustomerListPage from '../resources/customers/pages/CustomerListPage';
import CreateCustomersPage from '../resources/customers/pages/CreateCustomersPage';
import SupplierListPage from '../resources/suppliers/pages/SupplierListPage';
import SupplierDetailsPage from '../resources/suppliers/pages/SupplierDetailsPage';
import EditSupplierPage from '../resources/suppliers/pages/EditSupplierPage';
import CreateSuppliersPage from '../resources/suppliers/pages/CreateSuppliersPage';

const components = {
  MYSInvoiceFormComponent: MYSInvoiceForm,
  MYSCreditNoteFormComponent: MYSCreditNoteForm,
  MYSDebitNoteFormComponent: MYSDebitNoteForm,
  MYSRefundFormComponent: MYSRefundForm,
  MYSSBInvoiceFormComponent: MYSSBInvoiceForm,
  MYSSBCreditNoteFormComponent: MYSSBCreditNoteForm,
  MYSSBDebitNoteFormComponent: MYSSBDebitNoteForm,
  MYSSBRefundFormComponent: MYSSBRefundForm,
};

function getComponent(component) {
  return components[component];
}

const AuthenticatedRoutes = ({ features }) => {
  let configuredFeatures = features.map((feature) => (
    <React.Fragment key={feature.key}>
      {feature.key === 'invoices' && feature.list && (
        <Route path={`/${feature.key}`} element={<InvoiceList config={feature.list} />} />
      )}

      {feature.key === 'invoices' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<InvoiceDetails config={feature.details} />} />
      )}

      {feature.key === 'invoices' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'credit-notes' && feature.list && (
        <Route path={`/${feature.key}`} element={<CreditNoteList config={feature.list} />} />
      )}

      {feature.key === 'credit-notes' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<CreditNoteDetails config={feature.details} />} />
      )}

      {feature.key === 'credit-notes' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'refunds' && feature.list && (
        <Route path={`/${feature.key}`} element={<RefundList config={feature.list} />} />
      )}

      {feature.key === 'refunds' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<RefundDetails config={feature.details} />} />
      )}

      {feature.key === 'refunds' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'debit-notes' && feature.list && (
        <Route path={`/${feature.key}`} element={<DebitNoteList config={feature.list} />} />
      )}

      {feature.key === 'debit-notes' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<DebitNoteDetails config={feature.details} />} />
      )}

      {feature.key === 'debit-notes' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'self-billed-invoices' && feature.list && (
        <Route path={`/${feature.key}`} element={<SBInvoiceList config={feature.list} />} />
      )}

      {feature.key === 'self-billed-invoices' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<SBInvoiceDetails config={feature.details} />} />
      )}

      {feature.key === 'self-billed-invoices' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'self-billed-credit-notes' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'self-billed-credit-notes' && feature.list && (
        <Route path={`/${feature.key}`} element={<SBCreditNoteList config={feature.list} />} />
      )}

      {feature.key === 'self-billed-credit-notes' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<SBCreditNoteDetails config={feature.details} />} />
      )}

      {feature.key === 'self-billed-debit-notes' && feature.list && (
        <Route path={`/${feature.key}`} element={<SBDebitNoteList config={feature.list} />} />
      )}

      {feature.key === 'self-billed-debit-notes' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'self-billed-debit-notes' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<SBDebitNoteDetails config={feature.details} />} />
      )}

      {feature.key === 'self-billed-refunds' && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === 'self-billed-refunds' && feature.list && (
        <Route path={`/${feature.key}`} element={<SBRefundList config={feature.list} />} />
      )}

      {feature.key === 'self-billed-refunds' && feature.details && (
        <Route path={`/${feature.key}/:document_id`} element={<SBRefundDetails config={feature.details} />} />
      )}
    </React.Fragment>
  ));
  configuredFeatures.push(
    <React.Fragment key={'business-profiles'}>
      <Route path={`/business-profiles`} element={<BusinessProfileListPage />} />
      <Route path={`/business-profiles/:profile_id`} element={<BusinessProfileDetailsPage />} />

      <Route path={`/business-profiles/:profile_id/edit`} element={<EditBusinessProfilePage />} />

      <Route path={`/business-profiles/create`} element={<CreateBusinessProfilePage />} />
    </React.Fragment>,
    <React.Fragment key={'customers'}>
      <Route path={`/customers`} element={<CustomerListPage />} />
      <Route path={`/customers/:profile_id`} element={<CustomerDetailsPage />} />

      <Route path={`/customers/:profile_id/edit`} element={<EditCustomerPage />} />

      <Route path={`/customers/create`} element={<CreateCustomersPage />} />
    </React.Fragment>,
    <React.Fragment key={'suppliers'}>
      <Route path={`/suppliers`} element={<SupplierListPage />} />
      <Route path={`/suppliers/:profile_id`} element={<SupplierDetailsPage />} />

      <Route path={`/suppliers/:profile_id/edit`} element={<EditSupplierPage />} />

      <Route path={`/suppliers/create`} element={<CreateSuppliersPage />} />
    </React.Fragment>,
  );
  return configuredFeatures;
};

export default AuthenticatedRoutes;
