import React from 'react';
import { get } from 'lodash';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E9EBFF',
    color: theme.palette.common.black,
    borderRight: `1px solid ${theme.palette.divider}`, // Add right border
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  ' &:last-child th': {
    border: 0,
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
    height: 50,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'Submitted':
      return '#007BFF';
    case 'Valid':
      return '#28A745';
    case 'Invalid':
      return '#DC3545';
    case 'Cancelled':
      return '#6C757D';
    default:
      return 'transparent';
  }
};

const CustomTable = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    columns,
    filteredData,
    documentStatus,
    currentPage,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
    ActionsComponent,
    childComponentProps,
    isPaginationEnabled = true,
  } = props;

  const handleDocumentClick = (row) => {
    const action = childComponentProps.actions.find((action) => action.action === 'details');
    if (action) {
      navigate(action.target.replace(':id', row.document_id));
    }
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 1, boxShadow: theme.shadows[1] }}>
      <Table>
        {filteredData?.length > 0 && (
          <StyledTableHead>
            <StyledTableRow>
              {columns.map((column, index) => (
                <StyledTableCell key={index}>{column.columnName.toUpperCase()}</StyledTableCell>
              ))}
            </StyledTableRow>
          </StyledTableHead>
        )}
        <TableBody>
          {filteredData?.length > 0 ? (
            filteredData.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex} hover>
                {columns.map((column, colIndex) => (
                  <StyledTableCell
                    key={colIndex}
                    sx={{
                      padding: theme.spacing(0.5),
                      paddingLeft: theme.spacing(1.5),
                      border: `1px solid ${theme.palette.divider}`,
                      position: 'relative',
                      textAlign: column.isNumeric ? 'right' : 'left',
                      ...(column.mappingField === 'document_number' && {
                        borderLeft: `5px solid ${getStatusColor(get(row, documentStatus?.key))}`,
                        cursor: 'pointer',
                      }),
                    }}
                    onClick={() => column.mappingField === 'document_number' && handleDocumentClick(row)}
                  >
                    {column.columnName === 'Actions' ? (
                      ActionsComponent && (
                        <ActionsComponent {...childComponentProps} row={row} documentStatus={documentStatus} />
                      )
                    ) : column.columnName === 'Tax' ? (
                      <Box>
                        <Typography>{(get(row, 'tax_amount') || 0).toFixed(2)}</Typography>
                        <Typography>({get(row, 'tax_rate')}%)</Typography>
                      </Box>
                    ) : column.mappingField === 'document_number' ? (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleDocumentClick(row)}
                        >
                          {get(row, column.mappingField)}
                        </Typography>
                      </Box>
                    ) : column.isNumeric ? (
                      (get(row, column.mappingField) || 0).toFixed(2)
                    ) : (
                      get(row, column.mappingField) || 'N/A'
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={columns.length} height="150px" align="center">
                No Data available
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
      {isPaginationEnabled && filteredData?.length > 0 && (
        <StyledTablePagination
          component="div"
          count={totalCount}
          rowsPerPage={10}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default CustomTable;
