import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invoices: [],
  selectedInvoice: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  isInvoiceCreated: false,
  isInvoiceCancelled: false,
  loading: false,
  error: null,
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    fetchInvoicesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchInvoicesSuccess(state, action) {
      state.loading = false;
      state.invoices = action.payload.invoices;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchInvoicesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchInvoiceById(state) {
      state.loading = true;
      state.error = null;
    },
    fetchInvoiceByIdSuccess(state, action) {
      state.loading = false;
      state.selectedInvoice = action.payload.invoice;
    },
    fetchInvoiceByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createInvoiceRequest(state) {
      state.loading = true;
      state.error = null;
    },
    createInvoiceSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isInvoiceCreated = true;
    },
    createInvoiceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    markInvoiceCreated(state, action) {
      state.isInvoiceCreated = false;
    },
    cancelInvoiceRequest(state) {
      state.loading = true;
      state.error = null;
    },
    cancelInvoiceSuccess(state) {
      state.loading = false;
      state.isInvoiceCancelled = true;
    },
    cancelInvoiceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    markInvoiceCancelled(state) {
      state.isInvoiceCancelled = false;
    },
  },
});

export const {
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  fetchInvoicesFailure,
  fetchInvoiceById,
  fetchInvoiceByIdSuccess,
  fetchInvoiceByIdFailure,
  createInvoiceRequest,
  createInvoiceSuccess,
  createInvoiceFailure,
  markInvoiceCreated,
  cancelInvoiceRequest,
  cancelInvoiceSuccess,
  cancelInvoiceFailure,
  markInvoiceCancelled,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
