import React, { useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GoToDetailsActionMenuItem } from './GoToDetailsActionMenuItem';
import { GoToEditActionMenuItem } from './GoToEditActionMenuItem';

const CustomerActionsMenu = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.2)',
            },
          },
        }}
      >
        <GoToDetailsActionMenuItem customer={row} />
        <GoToEditActionMenuItem customer={row} />
      </Menu>
    </>
  );
};

export default CustomerActionsMenu;
