import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SupplierListPageActions() {
  const navigate = useNavigate();

  const handleCreateAction = () => {
    return navigate('/suppliers/create');
  };

  return (
    <>
      <Button key="add-new-Supplier" variant="contained" color="primary" onClick={handleCreateAction}>
        + Create New Supplier
      </Button>
    </>
  );
}

export default SupplierListPageActions;
