import React, { useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './components/Sidebar';
import { useConfig } from './hooks/useConfig';
import Header from './components/Header';
import lightTheme from './theme';
import Login from './Login';
import { fetchPdfTemplates } from './api/pdfTemplates/pdfTemplateSlice';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import { fetchCountryCodesRequest } from './api/constants/countryCodesSlice';
import { fetchMsicCodesRequest } from './api/constants/msicCodesSlice';
import { fetchStatesRequest } from './api/constants/statesSlice';
import GlobalSnackbar from './components/GlobalSnackbar';
import Loader from './components/Loader';

function App() {
  const config = useConfig();
  const { layout } = config;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchPdfTemplates());
      dispatch(fetchCountryCodesRequest({ search_key: 'country' }));
      dispatch(fetchMsicCodesRequest({ search_key: 'msic' }));
      dispatch(fetchStatesRequest({ search_key: 'state' }));
    }
  }, [dispatch, isAuthenticated]);

  const features = Object.entries(layout)
    .filter(([key]) => key !== 'header' && key !== 'sidebar')
    .map(([key, value]) => ({ key, ...value }));

  return (
    <ThemeProvider theme={lightTheme}>
      <Router>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          <GlobalSnackbar />
          {isAuthenticated && (
            <>
              <Header title={config.layout.header.title} />
              <Sidebar resources={layout.sidebar} />
            </>
          )}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: { xs: 2, md: 3 },
              marginTop: '8vh',
            }}
          >
            <Routes>
              <Route path="*" element={<Navigate to="/login" />} />
              <Route path="/" element={<Navigate to={isAuthenticated ? `${features[0].key}` : '/login'} replace />} />
              <Route path="/login" element={<Login redirectTo={`${features[0].key}`} />} />
              {isAuthenticated && AuthenticatedRoutes({ features })}
            </Routes>
          </Box>
          <Loader />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
